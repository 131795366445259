<template>
	<ul v-if="long" class="experts_list row">
		<li class="expert-style col-12 col-lg-6 d-block">
			<div class="expert d-block d-lg-flex align-items-center">
				<div class="col-md-8 col-lg-11 col-xl-10 h-100 m-auto d-flex flex-column">
					<div class="filter">
						<nuxt-link
							v-if="isProfessor !== null && slug"
							:to="$url.people(urlPeople, slug, language)"
						>
							<b-img-lazy
								:src="square_photo || ''"
								:alt="title"
								blank-color="lightgray"
								rounded="circle"
								center
								block
							/>
						</nuxt-link>
						<a
							v-else-if="description"
							role="button"
							href="javascript:void(null);"
							@click="showModal"
						>
							<b-img-lazy
								:src="square_photo || ''"
								:alt="title"
								blank-color="lightgray"
								rounded="circle"
								center
								block
							/>
						</a>
						<b-img-lazy
							v-else
							:src="square_photo || ''"
							:alt="title"
							blank-color="lightgray"
							rounded="circle"
							center
							block
						/>
					</div>
					<div class="text-content d-flex flex-column d-lg-none">
						<div class="title mt-4">
							{{ title }}
						</div>
						<p class="description">
							{{ job_title }}
						</p>
						<div v-if="description" class="bio_bottom">
							<nuxt-link
								v-if="isProfessor !== null && slug"
								:to="$url.people(urlPeople, slug, language)"
								class="more_link btn-link"
							>
								{{ $t('biography') }}
							</nuxt-link>
							<button v-else class="more_link btn-link" @click="showModal">
								{{ $t('biography') }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</li>
		<li class="bio-style col-12 col-lg-6 d-none d-lg-flex">
			<div class="d-flex align-self-center expert justify-content-center bio pl-lg-3">
				<div class="bio_top">
					<div class="title">
						{{ title }}
					</div>
					<p class="description">
						{{ job_title }}
					</p>
				</div>
				<div v-if="description" class="bio_bottom">
					<nuxt-link
						v-if="isProfessor !== null && slug"
						:to="$url.people(urlPeople, slug, language)"
						class="more_link btn-link"
					>
						{{ $t('biography') }}
					</nuxt-link>
					<button v-else class="more_link btn-link" @click="showModal">
						{{ $t('biography') }}
					</button>
				</div>
			</div>
		</li>
	</ul>
	<div v-else class="expert align-items-center">
		<div class="w-100">
			<div class="filter">
				<nuxt-link
					v-if="isProfessor !== null && slug"
					:to="$url.people(urlPeople, slug, language)"
				>
					<b-img-lazy
						:src="square_photo || ''"
						:alt="title"
						blank-color="lightgray"
						rounded="circle"
						center
						block
					/>
				</nuxt-link>
				<a
					v-else-if="description"
					role="button"
					href="javascript:void(null);"
					@click="showModal"
				>
					<b-img-lazy
						:src="square_photo || ''"
						:alt="title"
						blank-color="lightgray"
						rounded="circle"
						center
						block
					/>
				</a>
				<b-img-lazy
					v-else
					:src="square_photo || ''"
					:alt="title"
					blank-color="lightgray"
					rounded="circle"
					center
					block
				/>
			</div>
			<div class="title mt-4">
				{{ title }}
			</div>
			<div class="description mb-3">
				{{ job_title }}
			</div>
			<b-img-lazy
				v-if="logo"
				:src="logo.src"
				:alt="logo.alt"
				class="logo-img"
			/>
		</div>
		<div v-if="description" class="bio_bottom">
			<template v-if="id === 11942">
				<nuxt-link :to="'/en/professors/yurilevin/'" class="more_link btn-link">
					{{ $t('biography') }}
				</nuxt-link>
			</template>
			<template v-else-if="id === 15442 && $route.path.includes('crec')">
				<button class="more_link btn-link" @click="showModal">
					{{ $t('biography') }}
				</button>
			</template>
			<template v-else>
				<nuxt-link
					v-if="isProfessor !== null && slug"
					:to="$url.people(urlPeople, slug, language)"
					class="more_link btn-link"
				>
					{{ $t('biography') }}
				</nuxt-link>
				<button v-else class="more_link btn-link" @click="showModal">
					{{ $t('biography') }}
				</button>
			</template>
		</div>
		<div v-else class="bio_bottom" />
	</div>
</template>

<script>
// import { ROLES } from '~/utils/dictionaries';

const COUNT_P_FOR_SHORT_BIO = 2;

export default {
	inheritAttrs: false,
	/* eslint-disable vue/prop-name-casing */
	props: {
		id: { type: Number, required: true },
		title: { type: String, required: true },
		slug: { type: String, default: '' },
		job_title: { type: String, default: '' },
		square_photo: { type: String, default: '' },
		description: { type: String, default: null },
		short_description: { type: String, default: null },
		long: { type: Boolean, default: false },
		itemsLength: { type: Number, default: null },
		taxonomy: { type: Object, default: () => ({}) },
		primary_logos: { type: Array, default: () => [] },
		is_manager: { type: Number, default: 0 },
		is_professor: { type: Number, default: 0 },
		language: { type: String, default: 'ru' },
	},
	computed: {
		shortDescription() {
			if (!this.description) return '';

			return this.description.match(/<p.*?>.*?<\/p>/gis)
				?.filter((el, ind) => ind < COUNT_P_FOR_SHORT_BIO)
				?.join('');
		},
		logo() {
			const logoItems = this.primary_logos.filter((i) => i.src);
			if (logoItems.length === 0) return null;
			return logoItems[0];
		},
		isProfessor() {
			if (this.is_manager) return false;
			if (this.is_professor) return true;

			return null;
		},
		urlPeople() {
			return this.isProfessor ? 'professor' : 'management';
		},
	},
	methods: {
		showModal() {
			const h = this.$createElement;

			const messageVNode = h('div', { domProps: { innerHTML: this.description } });

			this.$bvModal.msgBoxOk([messageVNode], {
				title: `${this.title}`,
				buttonSize: 'sm',
				centered: true,
				size: 'xl',
				okDisabled: true, // Убираем кнопку OK
				footerClass: 'd-none', // Убираем footer
				hideHeaderClose: false, // Открываем крест (close) в header
				scrollable: true,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/components/home/panel-explist";
//@import "~/assets/styles/components/blocks/people/person";

.title {
	min-height: 3.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.expert-style {
	display: block;
}

.expert {
	.bio_top,
	.description,
	.text-content {
		flex: 1 1 auto;
	}
}

.experts_list {
	.bio-style {
		padding-top: 0;
		padding-bottom: 1rem;
	}
	.bio_top,
	.description,
	.text-content {
		flex: 0 1 auto;
	}
	.expert.bio {
		text-align: center;
		align-items: center;
	}
}
</style>

<i18n lang="yaml">
ru:
  biography: "Биография"
  modal-biography: "Биография"
  all-biography: "Вся биография"
en:
  biography: "Biography"
  modal-biography: "Biography of"
  all-biography: "All biography"
</i18n>
