import { render, staticRenderFns } from "./Person.vue?vue&type=template&id=31b78bb5&scoped=true"
import script from "./Person.vue?vue&type=script&lang=js"
export * from "./Person.vue?vue&type=script&lang=js"
import style0 from "./Person.vue?vue&type=style&index=0&id=31b78bb5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b78bb5",
  null
  
)

/* custom blocks */
import block0 from "./Person.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImgLazy} from 'bootstrap-vue'
    installComponents(component, {BImgLazy})
    

export default component.exports