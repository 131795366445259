<template>
	<div class="experts_block">
		<person
			v-if="items.length === 1"
			:id="items[0].id"
			:title="items[0].title"
			class="experts_list row"
			v-bind="items[0]"
			long
		/>
		<ul v-else class="experts_list row">
			<li v-for="person in items" :key="person.id" :class="columnClasses">
				<person
					:id="person.id"
					:key="person.id"
					:title="person.title"
					v-bind="person"
					:items-length="items.length"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
import Person from './Person.vue';

export default {
	components: {
		Person,
	},
	props: {
		items: { type: Array, required: true },
		shortcut: { type: Boolean, default: false },
	},
	computed: {
		columnClasses() {
			if (this.items.length === 2) return 'col-12 col-sm-6 col-lg-6';
			if (this.items.length === 3) return 'col-12 col-md-6 col-lg-4';

			if (this.shortcut) return 'col-12 col-md-6 col-lg-4';

			return 'col-12 col-sm-6 col-lg-3';
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-explist";
</style>
