<template>
	<div
		:class="'panel explist ' +
			(isHeaderIndent? ' mt-0 pt-1 ' : '') +
			(isFooterIndent? ' mb-0 pb-1 ' : '') +
			block.appearance"
	>
		<div v-if="block.is_accordion" class="container">
			<b-card no-body class="pane">
				<b-card-header header-tag="header" class="p-0" role="tab">
					<div v-b-toggle="'module-collapse-' + block.id" class="accordeon-pane">
						{{ block.block_title }}
					</div>
				</b-card-header>
				<client-only>
					<b-collapse :id="'module-collapse-' + block.id" class="theme-white" role="tabpanel">
						<b-card-body>
							<div
								v-if="block.description"
								class="description"
								v-html="block.description"
							/>
							<people v-if="people" :items="people" />
						</b-card-body>
					</b-collapse>
				</client-only>
			</b-card>
		</div>

		<div v-else class="container">
			<div v-if="isHeaderVisible" :class="'panel_header' + (isBrand ? ' sk' : '')">
				<div class="row align-items-end">
					<div class="col-12">
						<h2 v-if="block.block_title === null && 'type_titles' in fetchedData">
							Спикеры {{ fetchedData.type_titles.title_genitive }}
						</h2>
						<h2 v-else>
							{{ block.block_title }}
						</h2>
						<div
							v-if="block.description"
							class="description"
							v-html="block.description"
						/>
						<slides-menu
							v-if="hasTabs"
							v-model="tab"
							:items="tabs"
							disable-all
						/>
					</div>
				</div>
				<!-- <nuxt-link class="panel_control" to="#"></nuxt-link> -->
			</div>

			<people v-if="people" :items="people" />

			<div v-if="isFooterVisible" class="panel_footer" />
			<div v-if="block.footer" class="description" v-html="block.footer" />
			<dynamic-button
				v-if="block.button"
				:button="block.button"
				:block-id="block.id"
				:scenario="block.scenario"
			/>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';
import People from '~/components/blocks/people/People.vue';
import SlidesMenu from '~/components/refactor/SlidesMenu.vue';

export default {
	name: 'PanelConferenceSpeakers',
	components: {
		People,
		SlidesMenu,
	},
	mixins: [panel],
	props: {
		fetchedData: { type: Object, required: true },
	},
	data() {
		return {
			tab: '0',
		};
	},
	computed: {
		hasTabs() {
			return Array.isArray(this.block.blocks) && this.block.blocks.length > 0;
		},
		tabs() {
			if (!this.hasTabs) return null;

			const t = {};
			// eslint-disable-next-line camelcase
			this.block.blocks.forEach(({ block_title }, index) => {
				// eslint-disable-next-line camelcase
				t[index] = block_title;
			});
			return t;
		},
		people() {
			if (this.hasTabs) {
				return this.block.blocks[this.tab].content;
			}

			return this.block.content;
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-explist";
</style>
