<template>
	<div class="swiper_menu">
		<div v-swiper:menuSwiper="sliderOptions">
			<div class="swiper-wrapper">
				<div v-if="!disableAll" key="empty" class="swiper-slide">
					<button
						:class="{ active: isActive('') }"
						@click="$emit('input', '')"
					>
						{{ all }}
					</button>
				</div>
				<div
					v-for="item in options"
					:key="item.id"
					class="swiper-slide"
				>
					<button
						:class="{ active: item.active, 'text-muted': item.disabled }"
						:disabled="item.disabled"
						@click="$emit('input', item.value)"
					>
						{{ item.label }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { directive as swiper } from 'vue-awesome-swiper';

export default {
	name: 'SlidesMenu',
	directives: {
		swiper,
	},
	props: {
		items: { type: [Object, Array], required: true },
		value: { type: [Array, Object, String, Number], default: null },
		all: { type: String, default: 'Все' },
		labelKey: { type: String, default: 'title' },
		valueKey: { type: String, default: 'value' },
		disableAll: { type: Boolean, default: false },
	},
	data() {
		return {
			sliderOptions: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				// freeMode: true,
				loop: false,
			},
		};
	},
	computed: {
		options() {
			const map = (item, value) => ({
				value,
				label: this.getLabel(item),
				active: this.isActive(value),
				disabled: this.isDisabled(item),
			});
			return Array.isArray(this.items)
				? this.items.map((i) => map(i, i[this.valueKey]))
				: Object.keys(this.items).map((key) => map(this.items[key], key));
		},
	},
	methods: {
		isActive(value) {
			if (typeof this.value === 'number') {
				return this.value === Number(value);
			}

			if (typeof this.value === 'string') {
				return this.value === String(value);
			}

			if (Array.isArray(this.value)) {
				return this.value.includes(value);
			}

			return (this.value && this.value[value]) || false;
		},
		isDisabled({ disabled }) {
			return disabled === true;
		},
		getLabel(item) {
			if (typeof item === 'string') {
				return item;
			}
			if (typeof item === 'object') {
				return item[this.labelKey];
			}
			return '';
		},
	},
};
</script>
